import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				CityCar Rentals
			</title>
			<meta name={"description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:title"} content={"CityCar Rentals"} />
			<meta property={"og:description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});